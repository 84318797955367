import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M11.575 1.095a1 1 0 0 1 1.064.135l5.625 4.667a1 1 0 0 1-.014 1.55l-5 4q-.117.094-.25.147v5.962a1 1 0 1 1-2 0V2a1 1 0 0 1 .575-.905M13 9.086l3.042-2.433L13 4.129zm-3.766 4.171a1 1 0 0 1-.807 1.161c-1.748.315-3.174.843-4.133 1.464-.987.64-1.294 1.24-1.294 1.674 0 .288.127.638.51 1.04.388.408.997.82 1.825 1.189C6.99 20.52 9.345 21 12 21s5.01-.48 6.665-1.215c.828-.369 1.437-.781 1.825-1.189.383-.402.51-.752.51-1.04 0-.434-.307-1.035-1.294-1.674-.96-.62-2.385-1.15-4.133-1.464a1 1 0 0 1 .354-1.968c1.917.345 3.616.945 4.866 1.753 1.221.79 2.207 1.92 2.207 3.353 0 .939-.432 1.758-1.061 2.419-.625.656-1.48 1.201-2.462 1.637C17.512 22.485 14.867 23 12 23s-5.512-.515-7.477-1.388c-.981-.436-1.837-.981-2.462-1.637C1.432 19.314 1 18.495 1 17.555c0-1.431.986-2.561 2.207-3.352 1.25-.808 2.95-1.408 4.866-1.753a1 1 0 0 1 1.161.807",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }